<template>
  <full-text-page title="Privacy Policy">
    <p><b>Privacy Policy</b></p>
    <p><b>Runestone  Ltd ("Runestone", "us", "our" or "we") takes all the necessary measures to maintain the privacy rights of our users ("users" or "you").</b></p>
    <p>This document is provided to explain Runestone practices regarding the collection, use, and disclosure of information including personal information and data that users provide and we receive, collect, store, use, and manage in connection with our software that means all software programs distributed, published or otherwise made available by Runestone including but not limited to games accessed by means of a browser or other online communications method and downloadable/installable games for personal computer, mobile and other devices provided on any platform (for example, iOS, Android, PC and others) (the "Games") or any Runestone website (the "Website" or "Site"). Runestone games and website are collectively titled “Service(s)” under the means of this Privacy Policy. Please note that the scope of this Policy is limited to information and data collected or received by Runestone through your use of the Services. Runestone takes no responsibility for the actions of third party people, companies or entities, the content of their sites or services, the use of information or data you provide to them, or any products or services they may offer. Any link to those sites does not constitute our sponsorship of or affiliation with those people or companies.</p>
    <p>Runestone may access and share personal data, aggregate information, device-related information within the limits provided by this policy.</p>
    <p>Personal Data means any information relating to an identified or identifiable natural person/individual, while such person/individual is one who can be identified directly or indirectly in particular by reference to an identifier of general application or by reference to one or more factors specific to his physical, physiological, psychic, mental, economic, cultural or social identity.</p>
    <p><b>Personal data collected by Runestone may include but without limitation:</b></p>
    <ul>
      <li>login-name that is used by you to access Runestone Games Services;</li>
      <li>your e-mail address.</li>
    </ul>
    <p><b>Runestone may process such data if one of the following applies:</b></p>
    <ul>
      <li>you have given your consent for one or more specific purposes;</li>
      <li>provision of Data is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations thereof;</li>
      <li>processing is necessary for compliance with a legal obligation to which Runestone is subject;</li>
      <li>processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Runestone;</li>
      <li>processing is necessary for the purposes of the legitimate interests pursued by Runestone or by a third party.</li>
    </ul>
    <p>Device-related information is information about a user that is presented in a form distinguishable from information relating to other users, but not in a form that personally identifies contact and/or billing information for any user, or enables the recipient to communicate directly with any user unless agreed to by the user in advance of such communication. Such information may include but not limited to:</p>
    <ul>
      <li>Game data (such as your interactions with the game and with the other players inside the game via server log files, your player ID);</li>
      <li>Automatically collected data (such as IP Address, your device's operating system, browser type and language, referring and exit URLs, and number of clicks);</li>
      <li>Mobile device identifiers (such as your unique device ID (persistent/non-persistent, hardware type, medial access control ("MAC") address, international mobile equipment identity ("IMEI"), and your device name;</li>
      <li>General location information for the purpose of providing you with the correct version of the application;</li>
      <li>Precise-geo-location information from your mobile device (but only with your express consent).</li>
    </ul>
    <p><b>Aggregate information</b> is information that describes the habits, usage patterns, and demographics of users as a group but does not describe or reveal the identity of any particular user.</p>
    <p><b>Tracking Technologies</b></p>
    <p>We and our marketing and outsourcing partners, affiliates, or analytic service providers may use tracking technologies that are special technologies used to identify a user's computer/device and to "remember" things about your visit, such as your preferences or a user name and password. These technologies may include but without limitation <b>cookies, push notifications, web beacons, pixels</b>. You can disable cookies at any time by changing settings in your web browser. You can opt-out of receiving push-notifications from us by mailing Runestone at <a href="mailto:support@runestone.info">support@runestone.info.</a></p>
    <p><b>Please be informed that no tracking technologies will be used and no your data will be tracked if you pushed “Ask App Not to Track” on the App Tracking Transparency permission request in case you use any of the ios devices or you directly ask us not to track your data by sending us an email at <a href="mailto:support@runestone.info">support@runestone.info</a> or in any other tangible form inform us about it.</b></p>
    <p><b>The purposes of processing data</b></p>
    <p>Runestone primary goals in using information are to create your account, provide Services to you, improve our Services, contact you, conduct marketing and research, create reports for internal use and to meet contractual obligations. We use information collected through our Services for purposes described in this Policy or disclosed to you in connection with our Service. Runestone may have access and use information collected through the Services only to the extent that is necessary for the use of Services by you. For example, we may use your information to:</p>
    <ul>
      <li>Create game accounts and allow users to play our games;</li>
      <li>Identify and suggest connections with other Runestone users;</li>
      <li>Operate and improve our Services;</li>
      <li>Understand you and your preferences to enhance your experience and enjoyment using our Service;</li>
      <li>Ensure the Safety and Security – we verify accounts and activity, and promote safety and security on and off our Runestone Services such as by investigating suspicious activity or violations of our Terms of Service and this Privacy Policy, and to ensure our Services are being used legally. This purpose contains processes such as Infrastructure monitoring, Registration and authentication, Analytics, Hosting and backend infrastructure;</li>
      <li>Respond to your comments and questions and provide customer service;</li>
      <li>Provide and deliver products and services you request;</li>
      <li>Deliver advertising and marketing and promotional information to you;</li>
      <li>Send you related information, including confirmations, invoices, technical notices, updates, security alerts, and support and administrative messages;</li>
      <li>Communicate with you about promotions, rewards, upcoming events, and other news about products and services offered by Runestone and our selected partners;</li>
      <li>Enable you to communicate with other users;</li>
      <li>Link or combine it with other information we get from third parties, to help understand your preferences and provide you with better services;</li>
      <li>And in any other purpose in order to ensure the interaction between you and the Service.</li>
    </ul>
    <p>Subject to the terms of this Privacy Policy, with your consent we may use your contact information to process our promotional newsletters and/or email campaigns and to send you updates, security alerts, or administrative messages relating to Runestone Sites and/or Services. Your contact information will allow us to notify you about new features, enhancements, resolution of Runestone Sites and/or Services problems, handle and/or route your customer support questions and issues, and inform you of upgrade opportunities, sweepstakes, contests, prizes, and/or special events.</p>
    <p>We may use an IP address to surface the appropriate country-based version of Runestone Sites and/or Services to a user, or to exercise our ability to restrict access to the Site by users who have violated our Terms of Service.</p>
    <p><span style="font-style: italic;">We may use information collected from you to calculate aggregate, anonymized statistics about our user community for the purpose of enhancing our Runestone Sites and/or Services, or for security purposes.</span></p>
    <p><b>Information Sharing</b></p>
    <p>In accordance with applicable law in force, Runestone may share collected aggregate or anonymous information with advertisers, publishers, business partners and other third parties.</p>
    <p>Runestone also may share the personal information collected from you under the following circumstances in accordance with applicable law in force:</p>
    <ul>
      <li>Protection of rights: Runestone will share personal information if Runestone has a good faith belief that (i) access, use, preservation or disclosure of such information is reasonably necessary to satisfy any applicable law, regulation, legal process, such as a court order or subpoena, or a request by law enforcement or governmental authorities, (ii) such action is necessary to detect, prevent, or otherwise address fraud, security or technical issues associated with the Services, or (iii) such action is appropriate to protect the rights, property or safety of Runestone, its employees, clients, or users of the Services.</li>
      <li>Asset transfers: If Runestone becomes involved in a merger, acquisition or other transaction involving the sale of some or all of Runestone’ assets or a line of business, user information, including personal information collected from you through your use of the Services, could be included in the transferred assets. Should such an event occur, Runestone will use reasonable means to notify you through a prominent notice on the Services. By sharing your personal information with Runestone, you are giving Runestone consent to transfer your personal information to a third party in an asset transfer described here.</li>
      <li>Services providers: Runestone may share your personal information with third party vendors or services providers that Runestone engages for the purpose of fulfilling product orders or processing information on Runestone and your behalf. For example, Runestone may partner with other companies to provide customer services. Additionally, Runestone may partner with other companies to process, analyze, and/or store data, including, but not limited to, Analytical Data. While providing services for Runestone, these companies may access your personal information. To the extent applicable, Runestone requires these entities to comply with this Privacy Policy and appropriate confidentiality and security measures. Particularly Runestone may share your information with its partner Mailchimp (The Rocket Science Group LLC). Please read carefully Mailchimp’s privacy policy at <a href="https://www.intuit.com/privacy/statement/">Intuit Privacy Statement</a></li>
    </ul>
    <p>We guarantee that in no case Runestone will sell, rent personal information collected from you to third parties without notifying you.</p>
    <p><b>Disclosure of the information</b></p>
    <p>Runestone does not disclose your personal information except as approved by you or as described below:</p>
    <ul>
      <li>when we have a good faith belief that we have to disclose the information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements, or legal process (for example, a court order, search warrant or subpoena);</li>
      <li>to satisfy any laws or regulations that apply;</li>
      <li>where we believe in our sole discretion that the Service is being used in committing a crime, including to report such criminal activity or to share information with other companies and organizations for the purposes of fraud protection, credit risk reduction, and other security precautions;</li>
      <li>when we have a good faith belief that there is an emergency that poses a threat to the health and/or safety of you, another person or the public generally;</li>
      <li>to protect the rights or property of Runestone and other applicable third parties, including to enforce our Terms of Service.</li>
    </ul>
    <p><b>Interactions with third parties services independent of Our Services</b></p>
    <p>Please be aware that Runestone cannot control the activities of third parties to whom Runestone provides or from whom Runestone is provided data, and as such Runestone cannot guarantee that they adhere to the same privacy and security procedures as Runestone.</p>
    <p>If you use the Services on connected third-party applications or connect the Services to any third-party applications, like Apple (iOS), Google Play (Android), Amazon, or a Social Networking Site such as Facebook, Google+, Runestone may receive certain information about you from the provider of the third-party application. The information Runestone receives depends on the third-party application, your privacy settings and, if applicable, your friends' privacy settings on that third-party application. If you access the Services from a third-party application or connect the Services to a third-party application, you should also read that third-party application's Privacy Policy.</p>
    <p>When you click on a third party logo or URL displayed on the Services that links you to a different website, this Privacy Policy no longer applies and you must read the privacy policy of the third party to see how your personal information will be handled on their website. If you cannot find their privacy policy, then you should contact the respective webmasters of those websites to obtain information regarding their information collection, use and disclosure policies. Runestone is not responsible for their collection and use of your personal information by third parties.</p>
    <p><b>Forums and Private Messages</b></p>
    <p>Runestone Services may ensure communication between users by forums and the possibility of exchange by private messages. Any information that is disclosed in forums or by private messages provided by Services is public information and users should exercise caution when deciding to disclose personal information in such chat rooms and forums. If you voluntarily disclose information in a public area of the Runestone Sites and Services, such as a voice or text chat room or forum, that information may be collected and used by a third party to send you unsolicited messages, advertisements or for other purposes. We do not control the actions of these third parties in public areas of the Runestone Sites and Services, so if you disclose information in a public area, you do so at your own risk. We do not monitor or log any direct, private communication between users, unless it was requested by at least one participant in advance or if there is reason to believe that a user is using such direct communication in order to breach these or any applicable law. Runestone is not responsible for any consequences of such disclosure of personal information in a public area of the Runestone Sites and Services.</p>
    <p><b>Customer Service</b></p>
    <p>We may use your email address when you contact our customer service group to contact you about your gaming experience with Runestone games and notify you about company news and promotions. If you no longer wish to receive certain email notifications you may opt-out at any time by emailing us. Your mailing address may be used if we have merchandise or other physical promotional materials that we want to send to you. If you no longer wish to receive these types of promotional materials, you may opt-out from receiving them at any time by emailing us with the contact information provided in this policy.</p>
    <p><b>Advertising</b></p>
    <p>We may feature advertisements in our games, services and elsewhere, both ourselves and with the help of our partners, and collect and use data and information about you and your device (including the presence of applications on the device or in use at a particular time on the device) in connection with such advertising, including your viewing of and interaction with any such advertising, in order to deliver, enable, target and optimize advertising and promotional information that is more relevant to you and is based on your game and device usage, online behavior and interests (this is commonly referred to as online behavioral or interest based advertising). In addition, you may see our games advertised in other services. After clicking on one of these advertisements and installing our game, you will become a user of our Service. In order to verify the installs, a device identifier may be shared with the advertiser or third party advertiser or analytic partner.</p>
    <p><b>However we kindly ask you to pay attention, that we will not track your data and it will not be used for the advertising purposes if you refuse tracking as it is prescribed in section “tracking technologies” of this Privacy Policy.</b></p>
    <p><b>International Operation</b></p>
    <p>If you use Runestone services please be aware that you are sending information (including Personal information) to the United States of America where this information is stored on our virtual servers. That information may then be transferred to other countries outside of your country of residence. Such transfer depends on the type of information and how it is stored by us. These countries may not necessarily have data protection laws as comprehensive or protective as those in your country of residence; however, our collection, storage and use of your Personal Information will at all times continue to be governed by this Privacy Policy and Runestone will take reasonable steps to ensure that your data is treated securely.</p>
    <p><b>Accessing, Correcting, and Deleting Your Information</b></p>
    <p>Upon request, we will provide you with information about whether we hold any of your personal information. If your personal information changes, or if you no longer desire our Services, you may at any time correct, update, delete inaccuracies, or delete your personal information by making the change within your account settings or by contacting us within the contact information pointed in <b>“Contact Information of Data Controller”.</b> Furthermore you have the right to data portability and send Runestone related request. We will respond to your access request within a reasonable timeframe.</p>
    <p><b>Opting Out of Marketing</b></p>
    <p>You may opt-out of receiving promotional emails from us by emailing us. If you opt-out, we are still entitled to send you non-promotional emails, such as emails about your accounts or our ongoing business relations.</p>
    <p><b>Data Retention</b></p>
    <p>We process and store personal data for as long as it is required by the purpose they have been collected for.</p>
    <p>Therefore personal data collected from you will be retained for as long as your account is active or as needed to provide you services. We are also entitled to retain and use your information as necessary for the purposes of Runestone legitimate interests or to comply with our legal obligations, resolve disputes, and enforce our agreements. In order to get more information concerning the retention of personal data you may contact us at <a href="mailto:support@runestone.info">support@runestone.info.</a></p>
    <p><b>Our Policy Regarding Children</b></p>
    <p>Runestone Sites and Services are intended for general audiences and are not child-directed. We do not knowingly collect or solicit personal information from or direct or target interest based advertising to anyone under the age of 16 or knowingly allow such persons to use our Services. If you are under 16, please do not send any information about yourself to us, including your name, address, telephone number, or email address. No one under the age of 16 may provide any personal information. In the event that we learn that we have collected personal information from a child under age 16, we will delete that information as quickly as possible. If it’s occasioned that you are under the age of 16 and use any Runestone Services you are obliged to stop use Runestone Services immediately and delete your Accounts and any information related to you from the Services. If you believe that we might have any information from or about a child under the age of 16, please contact us. We urge parents to instruct their children to never give out their real names, addresses, or phone numbers, without permission, when on-line. If you are at the age between 16 and 18 you confirm and represent that your legal guardian is acquainted with and understands this Privacy Policy and has agreed to it.</p>
    <p><b>Data Controller</b></p>
    <p>Runestone processes Personal Information as a Controller as defined in <span style="font-style: italic;">General Data Protection Regulation</span> (EU) 2016/679 (GDPR). As a Controller Runestone may pass the information to Data Processors having data processing agreements ensuring compliance with GDPR concluded with Runestone. All processing procedures are performed in accordance with the highest security regulations. Such Processors may include cloud storage services, analytic services. These services provide the data security resources of the same quality as Runestone.</p>
    <p><b>User’s rights</b></p>
    <p>You have the following rights with respect to the personal data we hold about you:</p>
    <ul>
      <li>The right to know what data we hold about you: If you would like to know what personal data we hold about you, please contact us at <a href="mailto:support@runestone.info">support@runestone.info.</a> We seek to promptly respond to your inquiry.</li>
      <li>The right to have incomplete, incorrect, outdated, or unnecessary personal data corrected, deleted, or updated. The easiest way to correct, delete, or update the personal data you have provided to the Service is to log in to the Service and enter the necessary changes in the account settings of the Service. If you have additional questions regarding the correction, deletion, or updating of the personal data we hold about you, please contact us at <a href="mailto:support@runestone.info">support@runestone.info.</a></li>
    </ul>
    <p>Please note that deletion of your personal data may lead to the inability to continue your further gameplay. However the gameplay results that you have achieved until the deletion of your personal data will be saved. You will be able to continue the gameplay after reinstalling the game and providing consent to the processing of your personal data.</p>
    <ul>
      <li>The right to opt out of receiving electronic direct marketing communications from us: all electronic direct marketing communications that you may receive from us, such as e-mail messages and SMS-messages, give you an option of not receiving such communications from us in the future. - If you have any additional questions about electronic direct marketing received from us, please contact us at <a href="mailto:support@runestone.info">support@runestone.info.</a></li>
      <li>All our games contain integrated “Delete personal data’’ button at their menu. At any time you may push this button and all your personal data that may have been processed by us will be deleted. As well, please take into account that this will not reset your progress in the game, and you won't be receiving any in-game notifications, however you won't be able to continue playing. If you need instructions regarding the usage of such button in our games you may send us a request at <a href="mailto:support@runestone.info">support@runestone.info.</a> We will answer you at a reasonable time.</li>
      <li>The right to request us to transmit the data collected from you to another controller by mailing us at <a href="mailto:support@runestone.info">support@runestone.info.</a></li>
      <li>You have the right, under certain circumstances, to restrict the processing of your Data. Such circumstances may be, for example: unlawful processing or when Runestone no longer needs the personal data for the purposes of processing. In this case, Runestone will not process your Data for any purpose other than storing it. For doing so you may send us a request at <a href="mailto:support@runestone.info">support@runestone.info.</a></li>
      <li>You have the right to object to the processing of personal data by providing us a ground related to the particular situation to justify the objection. In order to object you may send a request at <a href="mailto:support@runestone.info">support@runestone.info.</a></li>
    </ul>
    <p><b>Data security</b></p>
    <p>Runestone takes commercially reasonable security measures to protect against unauthorized access to, or unauthorized alteration, disclosure or destruction of, sensitive data that you share and we collect and store. These security measures may include practices such as keeping your sensitive data on a secured server behind a firewall, transmitting sensitive information (such as a credit card number) entered on our site or mobile application using secure socket layer technology (SSL), internal reviews of our data collection practices and platforms, as well as physical security measures to guard against unauthorized access to systems where we store your information. Although we make good faith efforts to store the information collected on the Service in a secure operating environment that is not available to the public, we cannot guarantee the absolute security of that information during its transmission or its storage on our systems. Further, while we attempt to ensure the integrity and security of our network and systems, we cannot guarantee that our security measures will prevent third-party "hackers" from illegally obtaining access to this information. We do not warrant or represent that your information will be protected against, loss, misuse, or alteration by third parties.</p>
    <p>At all times, Runestone strives to take precautions, to a reasonable extent, to prevent unauthorised access to your personal data as well as the unauthorised use or falsification of these data and to minimise the corresponding risks. Nevertheless, the provision of personal data, whether this is done personally, by telephone or over the Internet, is always associated with risks and no technological system is fully exempt from the possibility of manipulation or sabotage.</p>
    <p>If Runestone detects any unauthorized access to your information Runestone is obliged to notify competent law enforcement bodies about that within 72 hours from the moment of such detection. If there is a high risk for your rights and freedoms as a result of such unauthorized access to your information Runestone is also obliged to notify you about that at a reasonable timeframe.</p>
    <p></p>
    <p><b>Changes to This Policy</b></p>
    <p>Runestone reserves the right to change this Policy at any time. If we decide to change this Policy, we will post those changes on this page. The changes will be effective when posted. Please check our website and particularly this page on a regular basis so that you remain aware of what information we collect and how we use it.</p>
    <p></p>
    <p>In the event of different interpretations of the provisions of this Privacy Policy in different languages the English version shall prevail</p>
    <p></p>
    <p><b>Contact Information of Data Controller</b></p>
    <p>Runestone AR LLC, Spyrou Kyprianou, 61 SK HOUSE, 4003, Limassol, Cyprus</p>
    <p><a href="mailto:support@runestone.info">support@runestone.info</a></p>
  </full-text-page>
</template>

<script>
import FullTextPage from "@/components/FullTextPage";

export default {
  name: "PrivacyPage",
  components: {FullTextPage}
}
</script>

<style scoped>

</style>