<template>
  <full-text-page title="Terms of Service">
    <p><b>Terms of Service</b></p>
    <p><b>1. Subject Matter</b></p>
    <p><b>1.1 The governing agreement</b></p>
    <p>The terms of this agreement ("Terms of Service") govern the relationship between You („you”, “user, “your”,
      “yours”), and Runestone LTD  and/or its Affiliates ("Runestone", "us", "our" or "we") regarding your
      use of Runestone games, websites and related services (collectively referred to as "Service"/"Services"). Use
      of the Service is also governed by Runestone Privacy Policy and any other relevant policies, which are
      incorporated herein by reference. For purposes of these Terms of Service, the term <b>"Affiliates"</b> means, with
      respect to any party, any person or entity which controls, is controlled by, or is under common control with, such
      party. This is a legally binding agreement between You and Runestone.</p>
    <p>Before accessing or using the Service, including browsing any Runestone website or accessing a game, you
      must agree to these Terms of Service and the Privacy Policy. You may also be required to register an account on
      the Service (an "Account"). By registering for an Account or otherwise using the Service, you represent that you
      are age 16 or older. You accept full responsibility for any unauthorized use of the Services by minors and are
      responsible for any use of your credit card or other payment instrument by minors.</p>
    <p>If you access the Services through a third-party platform like Apple, Google, Amazon and/or from Social
      Networking Site (“SNS”) such as Facebook, Google+ etc., you are obliged to comply with the third parties’ terms
      and conditions as well as these Terms of Services.</p>
    <p>BY INSTALLING, USING OR OTHERWISE ACCESSING THE SERVICE, YOU AGREE TO THESE TERMS OF SERVICE. IF YOU DO NOT AGREE
      TO THESE TERMS OF SERVICE, PLEASE DO NOT INSTALL, USE OR OTHERWISE ACCESS THE SERVICE. USE OF THE SERVICE IS VOID
      WHERE PROHIBITED.</p>
    <p><b>1.2. Updates to the Terms of Service and Privacy Policy</b></p>
    <p>Runestone reserves the right, at its discretion, to change, modify, add or remove portions of these Terms of
      Service, its Privacy Policy and other relevant Runestone policies at any time by posting the amended terms on
      the Runestone Service. If we decide to change these Terms of Service or any related policy, we will post
      those changes on this page. The changes will be effective when posted. You will be deemed to have accepted such
      changes by continuing to use the Service. If at any point you do not agree to any portion of the current version
      of our Terms of Service, the Runestone Privacy Policy, or any other Runestone policy relating to your
      use of the Service, your license to use the Service shall immediately terminate, and you must immediately stop
      using the Service.</p>
    <p><b>1.3. Grant of a Limited License to Use the Service</b></p>
    <p>Subject to your agreement and continuing compliance with these Terms of Service and any other relevant Runestone policies, Runestone grants you a non-exclusive, non-transferable, non-sublicensable, revocable limited
      license subject to the limitations below to access and use the Service for your own non-commercial entertainment
      purposes. You agree not to use the Service for any other purpose.</p>
    <p>The following restrictions apply to the use of the Service:</p>
    <ul>
      <li>You shall not create an Account or access the Service if you are under the age of 16. If you are under the age
        of 16 and use any Runestone Services you are obliged to stop use Runestone Services immediately and
        delete your Accounts and any information related to you from the Services. You shall restrict use by minors, and
        you will deny access to children under the age of 16. - You accept full responsibility for any unauthorized use
        of the Service by minors. You are responsible for any use of your credit card or other payment instrument (e.g.
        paypal) by minors.
      </li>
      <li>You shall not (or attempt to) purchase, sell, rent or give away your Account, create an Account using a false
        identity or information, or on behalf of someone other than yourself;
      </li>
      <li>You shall not use the Service if you have previously been removed by Runestone, or previously been banned
        from playing any Runestone game.
      </li>
      <li>You shall use your Account and/or Service only for non-commercial purposes;</li>
      <li>You shall not use your Account and/or the Service to advertise, or solicit, or transmit any commercial
        advertisements, including chain letters, junk or spam e-mail or repetitive or misleading messages to anyone.
      </li>
    </ul>
    <p>Furthermore You agree that you will not, under any circumstances:</p>
    <ul>
      <li>Engage in any act that Runestone deems to be in conflict with the spirit or intent of the Service or make
        improper use of Runestone support services;
      </li>
      <li>Use or engage (directly or indirectly) in the use of emulators, cheats, exploits, automation software, bots,
        hacks, mods or any software designed to modify or interfere with the Service, any Runestone game or any
        Runestone game experience or without Runestone express written consent, modify or cause to be modified
        any files that are a part of the Service or any Runestone game;
      </li>
      <li>Disrupt, interfere with or otherwise adversely affect the normal flow of the Service or otherwise act in a
        manner that may negatively affect other users' experience when using the Service or playing Runestone
        games. This includes win trading and any other kind of manipulation of rankings, taking advantage of errors in
        the Service to gain an unfair edge over other players and any other act that intentionally abuses or goes
        against the design of the Service;
      </li>
      <li>Disrupt, overburden, or aid or assist in the disruption or overburdening of any computer or server ("Server")
        used to offer or support the Service or any Runestone game environment;
      </li>
      <li>Institute, assist, or become involved in any type of attack, including without limitation distribution of a
        virus, denial of service attacks upon the Service, or other attempts to disrupt the Service or any other
        person's use or enjoyment of the Service;
      </li>
      <li>Attempt to gain unauthorized access to the Service, Accounts registered to others or to the devices, Servers,
        or networks connected to the Service by any means other than the user interface provided by Runestone,
        including but not limited to, by circumventing or modifying, attempting to circumvent or modify, or encouraging
        or assisting any other person to circumvent or modify, any security, technology, device, or software that is
        part of the Service;
      </li>
      <li>Post any information that is abusive, threatening, obscene, defamatory, libelous, or racially, sexually,
        religiously, or otherwise objectionable or offensive or engage in ongoing toxic behavior, such as by repeatedly
        posting information on an unsolicited basis;
      </li>
      <li>Post any information that contains nudity, excessive violence, or offensive subject matter or that contains a
        link to such content;
      </li>
      <li>Attempt to, or harass, abuse, or harm, or advocate or incite harassment, abuse, or harm of another person,
        group, including Runestone employees, including Runestone customer service representatives;
      </li>
      <li>Make available through the Service any material or information that infringes any copyright, trademark,
        patent, trade secret, right of privacy, right of publicity, or other right of any person or entity or
        impersonates any other person, including without limitation an Runestone employee;
      </li>
      <li>Forge any TCP/IP packet header or any part of the header information in any email or newsgroup posting, or in
        any way use the Services to send altered, deceptive or false source-identifying information;
      </li>
      <li>Reverse, engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for any
        underlying software or other intellectual property used to provide the Service or any Runestone game, or to
        obtain any information from the Service or any Runestone game using any method not expressly permitted by
        Runestone or conspire in the aforesaid acts;
      </li>
      <li>Solicit or attempt to solicit personal information from other users of the Service or any Runestone
        game;
      </li>
      <li>Collect or post anyone's private information, including personally identifiable information (whether in text,
        image or video form), identification documents, or financial information through the Service in any form or
        medium using our Service;
      </li>
      <li>Make modifications to, and rent, lease, loan, sell, distribute, copy (except to create a single copy for your
        own back-up purposes), or create any derivative works based on the Runestone Services or the Content, in
        whole or in part untill we have expressly authorized you to do so in writing;
      </li>
      <li>Impersonate another person, including without limitation any other user or any Runestone employee;</li>
      <li>Obtain advantage or information from our Services using methods not permitted by Runestone;</li>
      <li>Use, display, mirror or frame the Services or any individual element within the Services, Runestone name,
        any Runestone trademark, logo or other proprietary information, or the layout and design of any page or
        form contained on a page, without Runestone express written consent.
      </li>
    </ul>
    <p>In any way the aforesaid shedule of misconducts is not exhaustive. Runestone reserves the right to determine
      what conduct violates or may violate these Terms of Service or any Runestone policy or otherwise may be
      outside the intent or spirit of these Terms of Service or the Service itself. Any use of the Service in violation
      of these Terms of Service or any kind of actions set above are strictly prohibited, can result in the immediate
      revocation of your limited license and may subject you to liability for violations of law.</p>
    <p>NEITHER Runestone NOR ITS LICENSORS, IS LIABLE FOR ANY DAMAGES IN CONNECTION WITH YOUR USE OF ANY RUNESTONE SERVICES (INCLUDING LIABILITY FOR ANY CONSEQUENTIAL OR INCIDENTAL DAMAGES OR DAMAGE TO YOUR COMPUTER
      HARDWARE OR SOFTWARE), AND THE ENTIRE RISK OF USE, INCLUDING, WITHOUT LIMITATION, ANY DAMAGE TO YOUR DEVICE
      HARDWARE OR SOFTWARE, RESULTING FROM ANY USE OF THE Runestone SERVICES, RESIDES WITH YOU.</p>
    <p><b>1.4 Accessing the Service</b></p>
    <p>By accessing or using the Service you accept and agree to these Terms of Service and the Privacy Policy. You may
      also be required to register an account on the Service (an "Account"), or have an account with the applications
      provider for your device. If you are between the ages of 16 and 17, you represent that your legal guardian has
      reviewed and agreed to these Terms. You must provide all equipment and software necessary to connect to the
      Service. You are responsible for incurring any expenses to access the Service, including but without limitation
      internet connection or mobile fees.</p>
    <p>If you create an Runestone account within any Runestone Services, you must provide truthful, complete,
      actual and accurate information to us in creating such account. If there are reasonable grounds to suspect that
      you have provided any information that is inaccurate, not current or incomplete, Runestone may suspend or
      terminate your ability to use or access Runestone Services, and refuse any and all current or future use of
      or access to any or all Runestone Services (or any portion thereof).</p>
    <p>Runestone reserves the right to limit the number of accounts a user can establish. This limit is defined by
      Runestone solely.</p>
    <p>Please note that deletion of your personal data from the game may lead to the inability to continue your further
      gameplay. However the gameplay results that you have achieved until the deletion of your personal data will be
      saved. You will be able to continue the gameplay after reinstalling the game and providing consent to the
      processing of your personal data.</p>
    <p>You are solely responsible for all your activity while using any Runestone Services and for the security of
      your device system. You should not reveal, send or pass by any other way your username or password to any other
      person.</p>
    <p>You agree to indemnify and hold Runestone and their respective employees, contractors, officers, directors,
      shareholders, agents, representatives, vendors, content providers and any other officials directly or indirectly
      related to Runestone harmless for any improper or illegal use of any of Services and/or accounts. This
      includes illegal or improper use by someone to whom you have given permission to use your Runestone
      account(s) or whom you have negligently allowed to access your Runestone account(s). Runestone reserves
      the right to terminate your Runestone account(s) if any activity that occurs with respect to such account(s)
      violates these Terms of Service, Privacy Policy and/or other Runestone policies.</p>

    <p><b>1.5 Suspension and Termination of Account and Service</b></p>
    <p>Without limitation to any other remedies, Runestone may delete, suspend, terminate, limit, modify accounts
      or access to the services or any portions thereof, if Runestone suspects or if you have failed to comply with
      any of the Terms of Services, for any suspected or actual illegal activity, improper use of the Services; with or
      without any notice to you. Such actions may result in the deleting your account, information, persona, benefits,
      and in game purchases. Runestone is not and will not be held responsible for any information, persona,
      rankings, benefits and losses incurred, and is under no obligation to refund you for any of this.</p>
    <p>Runestone may also stop offering and/or supporting the Services including but not limited to any particular
      game or part of the Services at any time, at which point, your license to use the Services or a part thereof will
      be automatically terminated. In such event Runestone shall not be required to provide refunds, benefits or
      other compensation to users in connection with such termination. Such actions may also result in termination of
      your account, disabling of your access to the Services, or any part thereof including any content that may have or
      were in the process of submitting.</p>
    <p><b>1.6 Advertisements in Services.</b></p>
    <p>You understand that the Services may contatin feature advertisements from Runestone or third parties.
      Runestone may provide links on the Service to third party websites or vendors who may invite you to
      participate in a promotional offer in return for receiving an optional component of the Service and/or upgrades
      (such as virtual in-game items and/or virtual currency). Any charges or obligations you incur in your dealings
      with these third parties are your sole responsibility. Runestone makes no representation or warranty
      regarding any content, goods and/or services provided by any third party even if linked to from the Services, and
      will not bear any responsibility for any claim relating to any third party content, goods and/or services. The
      linked sites are not under the control of Runestone and may collect data or solicit personal information from
      you. Runestone is not responsible for their content, business practices or privacy policies, or for the
      collection, use or disclosure of any information those sites may collect. Further, the inclusion of any link does
      not imply endorsement by Runestone of these linked sites.</p>
    <p><b>2. Ownership, User Content, Intellectual property.</b></p>
    <p><b>2.1. Games and Service</b></p>
    <p>All rights, title and interest in and to the Service (including without limitation any games, titles, computer
      code, themes, objects, characters, character names, stories, dialogue, catch phrases, concepts, artwork,
      animations, sounds, musical compositions, audio-visual effects, methods of operation, moral rights, documentation,
      in-game chat transcripts, character profile information, recordings of games played using a Runestone game
      client, and the Runestone game clients and server software) are owned by Runestone. Runestone
      reserves all rights, including without limitation, all intellectual property rights or other proprietary rights,
      in connection with its games and the Service. Unless explicitly stated herein, nothing in these Terms of Services
      shall be construed as conferring any intellectual property rights or other proprietary rights.</p>
    <p><b>2.2. Accounts</b></p>
    <p>You acknowledge and agree that you will have no ownership or other property interest in the Account. You further
      acknowledge and agree that all interest and rights to the Account are and will be owned in perpetuity by Runestone, and only for the benefit of Runestone.</p>
    <p><b>2.3 User Content</b></p>
    <p><b>2.3.1. Submission of User Content</b></p>
    <p>"User Content" means any communications, images, sounds, and all the material, data, and information that you
      upload or transmit through a Runestone game client or the Service, or that other users upload or transmit,
      including without limitation any chat text. By transmitting or submitting any User Content while using the
      Service, you affirm, represent and warrant that such transmission or submission is (a) accurate and not
      confidential or misleading; (b) not in violation of any laws, contractual restrictions or other third party
      rights, and that you have permission from any third party whose personal information or intellectual property is
      comprised in the User Content; (c) free of viruses, adware, spyware, worms or other malicious code; and (d) you
      acknowledge and agree that any of your personal information within such content will at all times be processed by
      Runestone in accordance with its Privacy Policy.</p>
    <p><b>2.3.2. Responsibility and Content Monitoring</b></p>
    <p>Runestone assumes no responsibility for the conduct of any user submitting any User Content, and assumes no
      responsibility for monitoring the Service for inappropriate content or conduct. We do not, and cannot, pre-screen
      or monitor all User Content. Your use of the Service is at your own risk. By using the Service, you may be exposed
      to User Content that is offensive, indecent or otherwise not in line with your expectations. You bear all risks
      associated with the use of any User Content in connection with the Service. Our representatives or technology may
      monitor and/or record your interaction with the Service or communications (including without limitation chat text)
      when you are using the Service.</p>
    <p>By accepting the Terms of Services, you hereby provide your irrevocable consent to such monitoring and recording.
      You acknowledge and agree that you have no expectation of privacy concerning the transmission of any User Content,
      including without limitation chat text or voice communications.</p>
    <p>Runestone reserves the right to take any action with respect to any User Content that we deem necessary or
      appropriate in our sole discretion, including if we believe that such User Content violates the Terms of Service,
      including the Privacy Policy or any other Runestone policies, infringes any intellectual property right or
      other right of any person or entity, threatens the personal safety of users of the Service or the public or could
      create liability for Runestone. Runestone is entitled in its sole discretion to review, monitor,
      prohibit, edit, delete, disable access to or take any other necessary actions with User Content (including without
      limitation your User Content) without notice for any reason or for no reason at any time. If at any time Runestone
      chooses, in its sole discretion, to monitor the Service, Runestone nonetheless bears no responsibility
      for User Content and is not obliged to modify or remove any inappropriate User Content, no compensation will be
      provided for any ideas, schemes, uses of any information provided. We have the right, but not the obligation, in
      our sole discretion to edit, refuse to post, or remove any User Content and/or take any other appropriate
      actions.</p>
    <p><b>3. Information Use by Other Members of the Service</b></p>
    <p><b>3.1 Public Discourse</b></p>
    <p>Runestone may provide forums, blogs and/or chat features in the Services, where users can post information.
      Users may post topics of interest, general information, comments, and sometimes even personal information in these
      mediums. You understand that Runestone is not responsible for any information posted in the Services or
      whether others will view your information, comments and topics in the same light. Please do not submit, upload,
      write, or transmit any information in the Services you deem to be confidential. Runestone does not and will
      not bear any responsibility or liability of any use of any such information; no compensation will be provided for
      any ideas, schemes, uses of any information provided in these medium.</p>
    <p><b>3.2 User's responsibility for the Content</b></p>
    <p>You are solely responsible for the information that you post on, through or in connection with the Service and
      that you provide to others. Runestone may reject, refuse to post or delete any User Content for any or no
      reason, including, but not limited to, User Content that in the sole judgment of Runestone violates these
      Terms of Service.</p>
    <p><b>3.3 Your License to Runestone</b></p>
    <p>You hereby grant to Runestone an irrevocable, perpetual, transferable, fully paid-up, royalty-free,
      worldwide license (including the right to sublicense and assign to third party) and right to copy, reproduce, fix,
      adapt, modify, create derivative works from, manufacture, commercialize, publish, distribute, sell, license,
      sublicense, transfer, lease, transmit, publicly display, publicly perform, or provide access to electronically,
      broadcast, communicate to the public by telecommunication, display, perform, enter into device memory, and use and
      practice, in any way, your User Content as well as all modified and derivative works thereof in connection with
      our provision of the Service, including marketing and promotions of the Service. You also hereby grant to Runestone
      the right to authorize others to exercise any of the rights granted to Runestone under these Terms of
      Service. You further hereby grant to Runestone the unconditional, irrevocable right to use and exploit your
      name, likeness and any other information or material included in any User Content and in connection with any User
      Content, without any obligation to you. Except as prohibited by law, you waive any rights of attribution and/or
      any moral rights you may have in your User Content, regardless of whether your User Content is altered or changed
      in any manner. Runestone does not claim any ownership rights in your User Content and nothing in these Terms
      of Service is intended to restrict any rights that you may have to use and exploit your User Content. Runestone
      has no obligation to monitor or enforce your intellectual property rights in or to your User Content.</p>
    <p><b>3.4 User Interactions</b></p>
    <p>You are solely responsible for your interactions with other users of the Services and any other parties with whom
      you interact through the Services or the games. Runestone reserves the right, but has no obligation, to
      become involved in any way with these interactions and/or disputes. You will fully cooperate with Runestone
      to investigate any suspected unlawful, fraudulent or improper activity, including, without limitation, granting
      Runestone access to any portions of your Account and any information needed.</p>
    <p>If you have a dispute with one or more users, you release us (and Runestone’ officers, directors, agents,
      subsidiaries, joint ventures and employees) from claims, demands and damages (actual and consequential) of every
      kind and nature, known and unknown, arising out of or in any way connected with such disputes. You solely bear all
      kind of responsibilities arising out or connected with such claims, demands and damages. Runestone is not
      responsible for any consequences of your interactions with other users. You confirm that in the event that
      Runestone incurs any damages arising out of such disputes You are obliged to reimburse such damages in full
      amount.</p>
    <p><b>3.5 Disclosure</b></p>
    <p>Your information, and the contents of all of your online communications (including without limitation chat text,
      voice communications, IP addresses and your personal information) may be accessed and monitored only in that
      amount as necessary to provide the Service to you and may be disclosed: (i) when Runestone has a good faith
      belief that Runestone is required to disclose the information in response to legal process (for example, a
      court order, search warrant or subpoena); (ii) to satisfy any applicable laws or regulations (iii) where We
      believe that the Service is being used in the commission of a crime, including to report such criminal activity or
      to exchange information with other companies and organizations for the purposes of fraud protection and credit
      risk reduction; (iv) when We have a good faith belief that there is an emergency that poses a threat to the health
      and/or safety of you, another person or the public generally; and (v) in order to protect the rights or property
      of Runestone , including to enforce our terms of service.</p>
    <p><b>4. Purchase Terms, Refund and Fees</b></p>
    <p>While using the Services you may purchase, with "real world" money, a limited, personal, non-transferable,
      non-sublicensable, revocable license to use (a) "virtual currency", including but not limited to virtual cash or
      diamonds, all for use in Runestone games; (b) "virtual in-game items"; (с) bonus content. You do not in fact
      "own" the virtual in-game items, virtual currency and the amounts of any virtual in-game items, bonus content,
      virtual currency do not refer to any credit balance of real currency or its equivalent or reflect any stored value
      but instead constitute a measurement of the extent of your license. You are only allowed to purchase virtual
      in-game items, virtual currency, bonus content from us or our authorised partners through the Service, and not in
      any other way. The provision of virtual in-game items, virtual currency, bonus content for use in Runestone
      games is a service provided by Runestone that commences immediately upon acceptance by Runestone of your
      purchase.</p>
    <p>Runestone may manage, regulate, control, modify or eliminate virtual in-game items, virtual currency at any
      time, with or without notice. Runestone bears no liability to you or any third party in the event that
      Runestone exercises any such rights.</p>
    <p>The transfer of virtual in-game items, bonus content and virtual currency is prohibited except where expressly
      authorized in the Service. Other than as expressly authorized in the Service, you shall not sell, purchase, redeem
      or otherwise transfer virtual in-game items, bonus content or virtual currency to any person or entity or attempt
      any of the aforesaid, including but not limited to Runestone, another user or any third party. In the event
      of any claims arising out of such actions you bear full responsibility solely.</p>
    <p>Runestone does not issue any refunds or offer any exchanges of any virtual currency, bonus content and/or
      virtual in-game items purchased on or through the Services.</p>
    <p>IN ADDITION TO THE FOREGOING, YOU ACKNOWLEDGE THAT YOU WILL NOT BE REFUNDED, RECEIVE MONEY, OR BE OTHERWISE
      COMPENSATED FOR UNUSED VIRTUAL CURRENCY AND/OR VIRTUAL IN-GAME ITEMS, BONUS CONTENT OR OTHER GAME ASSETS WHEN THE
      SERVICES ARE TERMINATED FOR ANY REASON AND WITHOUT NOTICE OR AN ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS
      VOLUNTARY OR INVOLUNTARY OR IN ANY OTHER CASE.</p>
    <p>You agree to pay all fees and applicable taxes incurred by you or anyone using an Account registered to you.
      Runestone may revise the pricing for the goods and services offered through the Service at any time
      solely.</p>
    <p><b>4.1. Subscriptions</b></p>
    <p>Runestone may from time to time suggest users to purchase a subscription to the games or other services of
      Runestone, which may be referred to as a “Club Membership” or otherwise. By purchasing a subscription, the
      user may receive discounts on certain Runestone games, access to exclusive content and other benefits at the
      discretion of Runestone. The use of a subscription creates certain obligations for the Parties, in
      particular, the user's obligation to pay a certain fee for the operation of the subscription. The user
      independently chooses one of the payment methods offered in the subscription service. The user hereby confirms
      that he will not use a bank card or other means of payment, unless he has full right to use the appropriate means
      of payment to purchase a subscription. Runestone will not be held liable in any way if the user's children or
      other persons acting with or without such consent of the user use the user's means of payment to purchase a
      subscription. Runestone has the right at any time to unilaterally change the terms of the subscription,
      including its cost (the cost of a subscription already purchased by the user cannot be changed) without any
      notification, to cancel the subscription. At the same time, Runestone takes no responsibility for the
      functioning of the subscription, Runestone has no obligation to pay any compensation to the user or third
      parties, to indemnify for losses as a result of the purchase and / or use of the subscription by the user. All
      subscription terms not directly governed by these terms of use are governed by the terms in the subscription
      offer.</p>
    <p><b>5. Updates to the Service</b></p>
    <p>You understand that the Service is an evolving one. Runestone may require that you accept updates to the
      Service and to Runestone games you have installed on your device or computer. You acknowledge and agree that
      Runestone may update the Service and Runestone games, with or without notifying you. You may need to
      update third party software from time to time in order to get access to the Services.</p>
    <p><b>6. Disclaimer of Warranties</b></p>
    <p>WITHOUT LIMITING Runestone LIABILITY UNDER SECTION LIMITATION OF LIABILITY BELOW, THE SERVICE IS PROVIDED ON
      AN "AS IS" AND "AS AVAILABLE" BASIS FOR YOUR USE, WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING
      WITHOUT LIMITATION THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT,
      AND THOSE ARISING FROM COURSE OF DEALING OR USAGE OF TRADE. Runestone DOES NOT WARRANT THAT YOU WILL BE ABLE
      TO ACCESS OR USE THE SERVICE AT THE TIMES OR LOCATIONS OF YOUR CHOOSING; THAT THE SERVICE WILL BE UNINTERRUPTED OR
      ERROR-FREE; THAT DEFECTS WILL BE CORRECTED; OR THAT THE GAME OR THE SERVICE ARE FREE OF VIRUSES OR OTHER HARMFUL
      COMPONENTS.</p>
    <p><b>7. Limitation of Liability; Sole and Exclusive Remedy; Indemnification</b></p>
    <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, Runestone SHALL NOT BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL,
      CONSEQUENTIAL, SPECIAL, PUNITIVE OR OTHER SIMILAR DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF REVENUES, LOST
      PROFITS, LOST DATA OR BUSINESS INTERRUPTION OR OTHER INTANGIBLE LOSSES (HOWEVER SUCH LOSSES ARE QUALIFIED),
      ARISING OUT OF OR RELATING IN ANY WAY TO THESE TERMS OF SERVICE OR THE SERVICE ITSELF, WHETHER BASED ON CONTRACT,
      TORT OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT Runestone HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
      DAMAGES. TO THE EXTENT NOT PROHIBITED BY LAW, Runestone IS NOT OBLIGED TO REIMBURSE ANY DAMAGES. YOU
      ACKNOWLEDGE AND AGREE THAT YOUR SOLE REMEDY (AND Runestone EXCLUSIVE LIABILITY) FOR ANY DISPUTE WITH RUNESTONE
      IS TO STOP USING THE SERVICE AND TO CANCEL YOUR ACCOUNT.</p>
    <p>NOTHING IN THESE TERMS OF SERVICE SHALL AFFECT THE STATUTORY RIGHTS OF ANY CONSUMER OR EXCLUDE OR RESTRICT ANY
      LIABILITY RESULTING FROM GROSS NEGLIGENCE OR WILLFUL MISCONDUCT OF Runestone OR FOR DEATH OR PERSONAL INJURY
      ARISING FROM ANY NEGLIGENCE OR FRAUD OF Runestone.</p>
    <p>You agree to indemnify, defend and hold Runestone (and our officers, directors, agents, subsidiaries, joint
      ventures and employees) harmless from any claim, demand, damages or other losses, including reasonable attorneys'
      fees, asserted by any third-party resulting from or arising out of your use of the Service, or any breach by you
      of these Terms of Service and the Privacy Policy, however the foregoing does not apply if the infringement of
      rights is not attributable to your intentional or negligent behavior.</p>
    <p><b>8. Applicable Law, Dispute Resolution</b></p>
    <p>These and the rights of the parties hereunder shall be governed by and construed in accordance with the laws of
      Russian Federation, exclusive of conflict or choice of law rules.</p>
    <p>If a dispute arises between you and Runestone, we strongly encourage you to first contact us directly to
      seek a resolution by going to our customer support site at <a href="mailto:support@runestone.info">support@runestone.info.</a> You agree that any claim or
      dispute you may have against Runestone must be resolved exclusively by the state courts at the place of
      Runestone’ location.</p>
    <p><b>9. Severability</b></p>
    <p>if any part of these Terms or the Privacy Policy or any other Policy is not enforceable, the rest of these Terms,
      Privacy Policy or any other Policy still applies and is binding and any unenforceable term will be substituted
      reflecting our intent as closely as possible.</p>
    <p><b>10. Miscellaneous</b></p>
    <p><b>10.1. Assignment</b></p>
    <p>Runestone may assign or delegate these Terms of Service and/or the Runestone Privacy Policy, in whole
      or in part, to any person or entity at any time with or without your consent. You may not assign or delegate any
      rights or obligations under the Terms of Service or Privacy Policy without Runestone prior written consent,
      and any unauthorized assignment and delegation by you is ineffective.</p>
    <p><b>10.2. Supplemental Policies</b></p>
    <p>Runestone may publish additional policies related to specific services such as forums, contests or loyalty
      programs. Your right to use such services is subject to those specific policies and these Terms of Service.</p>
    <p><b>10.3. Entire Agreement</b></p>
    <p>These Terms of Service, any supplemental policies and any documents expressly incorporated by reference herein
      (including the Runestone Privacy Policy), contain the entire understanding of you and Runestone, and
      supersede all prior understandings of the parties hereto relating to the subject matter hereof, whether
      electronic, oral or written, or whether established by custom, practice, policy or precedent, between you and us
      with respect to the Service.</p>
    <p><b>10.4. No Waiver</b></p>
    <p>The failure of Runestone to require or enforce strict performance by you of any provision of these Terms of
      Service or the Runestone Privacy Policy or failure to exercise any right under them shall not be construed as
      a waiver or relinquishment of Runestone right to assert or rely upon any such provision or right in that or
      any other instance.</p>
    <p>The express waiver by Runestone of any provision, condition, or requirement of these Terms of Service or the
      Runestone Privacy Policy shall not constitute a waiver of any future obligation to comply with such
      provision, condition or requirement.</p>
    <p>Except as expressly and specifically set forth in these Terms of Service, no representations, statements,
      consents, waivers, or other acts or omissions by Runestone shall be deemed a modification of these Terms of
      Service nor legally binding, unless documented in physical writing, hand signed by You and a duly appointed
      officer of Runestone.</p>
    <p><b>10.5. Notices</b></p>
    <p>We may notify you via postings on the Runestone' web-site, and via e-mail or any other communications means
      to contact information you provide to us. All notices given by you or required from you under these Terms of
      Service or the Runestone Privacy Policy shall be in writing and addressed to: Runestone LTD ,
      Spyrou Kyprianou, 61 SK HOUSE, 4003, Limassol, Cyprus. Any notices that you provide without compliance
      with this Section on Notices shall have no legal effect.</p>
    <p><b>10.6. Equitable Remedies</b></p>
    <p>You acknowledge that the rights granted and obligations made under these Terms of Service to Runestone are
      of a unique and irreplaceable nature, the loss of which shall irreparably harm Runestone and which cannot be
      replaced by monetary damages alone so that in the event of any breach or anticipatory breach by you Runestone
      shall be entitled to injunctive or other equitable relief including but without limitation specific performance
      (without the obligations of posting any bond or surety or proof of damages) in addition to all other remedies
      available to the Disclosing Party at law or in equity, including but not limited to general, indirect and
      consequential damages, including lost profit, indemnification for every loss, cost, damage or expense, and other
      remedies which may be available. The Parties agree that the indemnification of the damages, lost profit, costs
      shall be made in a full amount. You irrevocably waive all rights to seek injunctive or other equitable relief, or
      to enjoin or restrain the operation of the Service or any Runestone game, exploitation of any advertising or
      other materials issued in connection therewith, or exploitation of the Service or any content or other material
      used or displayed through the Service and agree to limit your claims to claims for monetary damages, limited by
      Section 7 (if any).</p>
    <p><b>10.7. Force Majeure</b></p>
    <p>Runestone shall not be liable for any delay or failure to perform resulting from causes outside the
      reasonable control of Runestone, including without limitation any failure to perform hereunder due to
      unforeseen circumstances or cause beyond Runestone control such as acts of God, war, terrorism, riots,
      embargoes, acts of civil or military authorities, fire, floods, accidents, strikes, or shortages of transportation
      facilities, fuel, energy, labor or materials.</p>
    <p><b>10.8 Partnership</b></p>
    <p>You agree that your use of the Services does not constitute any joint venture, partnership, employment, or agency
      relationship between you and Runestone as a result of this Agreement.</p>
    <p><b>10.9 Third Parties</b></p>
    <p>No person who is not a party to this Agreement shall have any rights under this Agreement unless such right has
      been validly assigned to such person in accordance with the terms of this Agreement.</p>
    <p><b>10.10. Language of the Terms</b></p>
    <p>If We provide a translated version of these Terms, Runestone Privacy Policy, or any other terms or policy,
      it is for informational purposes only. If the translated version means something different than the English
      version, then the English meaning will be the one that applies.</p>
  </full-text-page>
</template>


<script>
import FullTextPage from "@/components/FullTextPage";

export default {
  name: "TermsPage",
  components: {FullTextPage}
}
</script>

<style scoped>

</style>