<template>
  <div id="main-container" class="main-container">
    <div class="main-content-container">
      <div id="main-center">
        <div id="main-title">RUNESTONE</div>
        <div id="main-coming-soon">Coming soon...</div>
      </div>
      <div id="footer">
        <div id="copyrights" class="footer-block">
          <span>&copy; 2022 Runestone LTD. All Rights Reserved.</span>
        </div>
        <div id="site-menu" class="footer-block">
          <span><a class="site-menu-span" href="#/privacy">Privacy Policy</a></span>
          <span><a class="site-menu-span" href="#/terms">Terms of Use</a></span>
        </div>
        <div id="mail-button-wrap" class="footer-block">
          <span class="address-span">Runestone Ltd, Spyrou Kyprianou, 61 SK HOUSE, 4003, Limassol, Cyprus</span>
          <a class="mail-button" href="mailto:support@runestone.info"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainPage'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-container {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  overflow: hidden;
  height: 100%;
  min-height: 780px;
  min-width: 1280px;
}

#main-title {
  margin-top: 200px;
  font-family: Helvetica, Arial, serif;
  font-size: 81px;
  color: #E64138;
}

.main-content-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#main-center {
  position: relative;
  width: 887px;
  margin: 0 auto;
}

#main-coming-soon {
  font-weight: Bold;
  font-size: 64px;
  color: #000;
  margin-top: 200px;
  font-family: IstokWeb-Bold;
}

.mail-button {
  width: 72px;
  height: 72px;
  display: inline-block;
  position: relative;
  right: 24px;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 24px;
  font-size: 18px;
  height: 72px;
}

.footer-block {
  height: 72px;
  padding-left: 24px;
  padding-right: 24px;
  width: 33.33%;
  line-height: 72px;
  vertical-align: bottom;
  position: relative;
}

#copyrights {
  text-align: left;
  vertical-align: text-bottom;
}

#site-menu {

}

.site-menu-span {
  padding-left: 8px;
  padding-right: 8px;
  text-align: left;
}

.address-span {
  padding-left: 8px;
  padding-right: 8px;
  text-align: left;
  font-size: 16px;
  line-height: 72px;
  vertical-align: middle;
  position: absolute;
  right: 140px;
}


#mail-button-wrap {
  text-align: right;
}

</style>
