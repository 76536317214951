<template>
  <component :is="currentView" />
</template>

<script>
import MainPage from './pages/MainPage.vue'
import PrivacyPage from './pages/PrivacyPage.vue'
import TermsPage from './pages/TermsPage.vue'
import NotFoundPage from './pages/NotFoundPage.vue'

const routes = {
  '/': MainPage,
  '/privacy': PrivacyPage,
  '/terms': TermsPage
}

export default {
  data() {
    return {
      currentPath: window.location.hash
    }
  },
  computed: {
    currentView() {
      return routes[this.currentPath.slice(1) || '/'] || NotFoundPage
    }
  },
  mounted() {
    window.addEventListener('hashchange', () => {
      this.currentPath = window.location.hash
    })
  }
}
</script>

<style>

#app {
  position: absolute;
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
}

a {

}

a:hover {

}

</style>