<template>
  <div class="fulltext-background"></div>
  <div id="fulltext-container">
    <div class="fulltext-header">
      <a href="#/" id="small-logo"></a>
    </div>
    <div class="fulltext-main-container">
      <h1 class="fulltext-title">{{ title }}</h1>
      <div class="fulltext-content"><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FullTextPage",
  props: {
    title: String
  }
}
</script>

<style scoped>
h1 {
  margin: 0;
}

#fulltext-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin: 0;
  padding-bottom: 76px;
}

.fulltext-background {
  z-index: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#small-logo {
  position: absolute;
  background-image: url(../assets/icon.png);
  background-repeat: no-repeat;
  background-size: cover;
  left: 24px;
  top: 24px;
  display: block;
  width: 128px;
  height: 128px;
}

.fulltext-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 310px;
}


.fulltext-main-container {
  margin-top: 105px;
}

.fulltext-title {
  font-size: 72px;
  color: #000000;
}

.fulltext-content {
  position: relative;
  width: 1200px;
  left: 50%;
  margin-left: -600px;
  font-size: 24px;
  margin-top: 10px;
  color: #000000;
  text-align: left;
}

</style>